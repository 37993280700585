.home-section {
    padding: 40px 0 40px;
}

.scrollable {
    position: relative;
    width: 100%;
    height: 100vh;
}

.section-intro {
    padding: 100px 0 30px;
    // background: #2b303b url('../../../images/home-bg-black.png') center center/cover no-repeat;
    background: #3a393e;
    color: #ffffff;
    .egret {
        font-size: 36px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 1px;
        text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.48);
    }
    h1 {
        font-size: 32px;
        margin: 2rem 0 1rem;
        font-weight: 500;
    }
    p {
        font-size: 16px;
        max-width: 450px;
        margin: 0 auto 1.4rem;
    }
    img {
        display: block;
        margin: 0 auto;
    }
}

.section-demos {
    position: relative;
    background: #ffffff;
    .demo-box-wrap {
        padding: 1.5rem;
    }
    .demo-box {
        position: relative;
        border-radius: 8px;
        transition: .2s all ease-in-out;
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
        &:hover {
            box-shadow: 0 10px 16px rgba(0, 0, 0, .2);
            .caption {
                opacity: 1;
                transform: translateY(-10px)
            }
        }
        .caption {
            opacity: 0;
            background: #ffffff;
            padding: 1rem;
            position: absolute;
            z-index: 99;
            top: -20px;
            left: 0;
            right: 0;
            margin: auto;
            border-radius: 8px;
            width: 220px;
            transition: .2s all ease-in-out;
            box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
        }
        h3 {
            margin: 0 0 1rem;
            font-size: 22px;
            font-weight: 300;
        }
        img {
            width: 100%;
            height: auto;
            float: left;
        }
        .screenshot {
            overflow: hidden;
            border-radius: 8px;
            cursor: pointer;
        }
    }
}